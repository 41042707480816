<template>
  <h1>Usuários</h1>

  <router-link to="/config/users/new" type="button" class="btn btn-light"><font-awesome-icon :icon="['fas', 'plus']" /></router-link>

  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Nome</th>
        <th scope="col">E-mail</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in users" :key="user.userId" @click="edit(user.userId)" :class="{inactive: user.status == 'inactive'}">
        <td>{{ user.userId }}</td>
        <td>
          {{ user.name }}
          <span class="badge bg-primary" v-if="user.admin == 1">admin</span>
          <span class="badge bg-danger" v-if="user.status == 'blocked'">bloqueado</span>
        </td>
        <td>{{ user.email }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      users: [],
    };
  },
  computed: {
    ...mapGetters(['apiEndpoint'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    newUserListener(event) {
      if (event.code == 'Insert') {
        this.$router.push('/config/users/new');
      }
    },
    async listUsers() {
      this.setLoadingOn();
      try {
        const { data } = await axios.get(`${this.apiEndpoint}/users`);

        this.users = data;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Não foi possível ler a lista de usuários.",
        });
      } finally {
        this.setLoadingOff();
      }

    },
    edit(userId) {
      this.$router.push("/config/users/edit/" + userId);
    }
  },
  mounted() {
    this.listUsers();

    window.addEventListener('keydown', this.newUserListener);
  },
  unmounted() {
    window.removeEventListener("keydown", this.newUserListener);
  },
};
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }

  tbody tr.inactive {
    text-decoration: line-through;
  }

</style>